import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";
import Layout from "../components/Layout";
import "../style/atlas.scss";
import { assetServer } from "../utils/Box";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Mousewheel } from "swiper/modules";
import ArrowLeft from "/assets/icons/mui/arrow-left.svg";
import ArrowRight from "/assets/icons/mui/arrow-right.svg";

const isBrowser = typeof window !== "undefined";

const translations = {
  watch: {
    en: "Watch video",
    es: "Ver video",
    it: "Guarda video",
  },
  send: {
    en: "Send",
    es: "Enviar",
    it: "Inviare",
  },
  request: {
    en: "Request a demo",
    es: "Request a demo",
    it: "Richiedi una demo",
  },
};

function AtlasTypeButton({ title, picture, index }) {
  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        className={"type position-" + index.toString()}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <button className="mach9 blue" onClick={() => setShowModal(true)}>
          <div>{title}</div>
        </button>
      </div>
      <div className={"modal" + (showModal ? " show" : " hide")}>
        <div className="bg" onClick={() => setShowModal(false)}></div>
        <div className="content">
          <div
            style={{
              position: "absolute",
              right: "1rem",
              top: "1rem",
              cursor: "pointer",
            }}
            onClick={() => setShowModal(false)}
          >
            X
          </div>
          <div>
            <img src={picture} style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    </>
  );
}
const AtlasPage = ({ data, pageContext, path, location }) => {
  const { frontmatter } = data.markdownRemark;
  const content = frontmatter[pageContext.language || "en"];
  const carouselRef = useRef();
  const firstEleRef = useRef();
  const formRef = useRef();

  const [showVideo, setShowVideo] = useState(false);

  const homeUrl =
    pageContext.language === "en" ? "/" : `/${pageContext.language}/`;
  const [selectedSolution, setSelectedSolution] = useState(
    Math.floor(content.section3.solutions.length / 2) || null
  );

  useEffect(() => {
    carouselRef.current.scroll({
      left: firstEleRef.current.offsetWidth * (selectedSolution + 2),
      behavior: "smooth",
    });
  }, [selectedSolution]);

  const handleTouchStart = (e) => {
    const touchStartX = e.touches[0].clientX;
    const touchStartY = e.touches[0].clientY;
    const swipeTolerance = 30; // Tolerance for vertical swipes
    let swipeDirection = null;

    const handleTouchMove = (moveEvent) => {
      const touchMoveX = moveEvent.touches[0].clientX;
      const touchMoveY = moveEvent.touches[0].clientY;
      const deltaX = touchMoveX - touchStartX;
      const deltaY = touchMoveY - touchStartY;

      if (
        Math.abs(deltaX) > Math.abs(deltaY) &&
        Math.abs(deltaX) > swipeTolerance
      ) {
        swipeDirection = deltaX > 0 ? "right" : "left";
        // Prevent vertical scrolling when swiping horizontally
        moveEvent.preventDefault();
      }
    };

    const handleTouchEnd = () => {
      if (swipeDirection === "left") {
        setSelectedSolution((prevIndex) =>
          Math.min(prevIndex + 1, content.section3.solutions.length - 1)
        );
      } else if (swipeDirection === "right") {
        setSelectedSolution((prevIndex) => Math.max(prevIndex - 1, 0));
      }
      // Remove event listeners after touch end
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };

    // Add event listeners for touch move and touch end
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
  };

  const handleScroll = (e) => {
    const delta = Math.sign(e.deltaY);
    setSelectedSolution((prevIndex) => {
      let newIndex = prevIndex + delta;
      if (newIndex < 0) {
        newIndex = 0;
      } else if (newIndex >= content.section3.solutions.length) {
        newIndex = content.section3.solutions.length - 1;
      }
      return newIndex;
    });
  };

  const handleArrowClick = (direction) => {
    if (direction === "left") {
      setSelectedSolution((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (direction === "right") {
      setSelectedSolution((prevIndex) =>
        Math.min(prevIndex + 1, content.section3.solutions.length - 1)
      );
    }
  };

  return (
    <Layout language={pageContext.language || "en"} path={path}>
      <div id="atlas-page">
        <section id="atlas-hero">
          <div
            className="bg"
            style={{
              backgroundImage: `url(${content.herosection.backgroundimage.publicURL})`,
            }}
          ></div>
          <div className="description">{content.herosection.description}</div>
        </section>
        <section id="atlas-video">
          <div
            className="bg left"
            style={{
              backgroundImage: `url(${content.section2.backgroundimageleft.publicURL})`,
            }}
          ></div>
          <div
            className="bg right"
            style={{
              backgroundImage: `url(${content.section2.backgroundimageright.publicURL})`,
            }}
          ></div>
          <div className="main-content">
            <div className="cube"></div>
            <button className="mach9" onClick={() => setShowVideo(true)}>
              <div>
                {translations.watch[pageContext.language]}
                <ArrowIcon />
              </div>
            </button>
          </div>
          <div
            className={
              "video-container" + (showVideo ? " fade-in" : " fade-out")
            }
            style={{ maxWidth: "100%", left: 0 }}
          >
            {showVideo && (
              <video
                width="320"
                height="240"
                id="video-atlas"
                controls={true}
                loop={false}
                muted={true}
                preload={"auto"}
                autoPlay={true}
                src={
                  assetServer +
                  "/videos/mach9_atlas_explainer_cleared_atlas_v2.mp4"
                }
                type="video/mp4"
                style={{ width: "100%", height: "100%" }}
              ></video>
            )}
            <button
              className="mach9"
              style={{
                position: "fixed",
                top: "2rem",
                right: "2rem",
              }}
              onClick={() => setShowVideo(false)}
            >
              <div>X</div>
            </button>
          </div>
        </section>

        <section
          id="atlas-solutions"
          onWheel={handleScroll}
          onTouchStart={handleTouchStart}
          onTouchMove={(e) => e.preventDefault()}
        >
          <div>
            <div className="catchy">{content.section3.title1}</div>
            <h2>{content.section3.title2}</h2>
            <p>{content.section3.description}</p>
          </div>
          <div id="carousel-container">
            <button
              className={`carousel-arrow left ${
                selectedSolution === 0 ? "hide" : ""
              } `}
              onClick={() => handleArrowClick("left")}
            >
              <ArrowLeft />
            </button>
            <div id="carousel" ref={carouselRef}>
              <div className="carousel-ele empty" ref={firstEleRef}></div>
              <div className="carousel-ele empty"></div>
              <div className="carousel-ele empty"></div>
              <div className="carousel-ele empty"></div>
              {content.section3.solutions?.map((solution, index) => (
                <div
                  className="carousel-ele"
                  onClick={() => setSelectedSolution(index)}
                >
                  <div className="icon-wrapper">
                    <div
                      className="icon"
                      style={{
                        backgroundImage: `url(${solution.icon.publicURL})`,
                        width: `${
                          80 *
                          (1 -
                            (1 * Math.abs(index - selectedSolution)) /
                              content.section3.solutions.length)
                        }%`,
                        opacity:
                          0.5 +
                          0.5 *
                            (1 -
                              (3 * Math.abs(index - selectedSolution)) /
                                content.section3.solutions.length),
                      }}
                    ></div>
                    <div
                      className="text"
                      style={{
                        opacity: index === selectedSolution ? 1 : 0,
                      }}
                    >
                      <h3>{solution.title}</h3>
                      <p>{solution.description}</p>
                    </div>
                  </div>
                </div>
              ))}
              <div className="carousel-ele empty"></div>
              <div className="carousel-ele empty"></div>
              <div className="carousel-ele empty"></div>
              <div className="carousel-ele empty"></div>
            </div>
            <button
              className={`carousel-arrow right ${
                selectedSolution === 4 ? "hide" : ""
              } `}
              onClick={() => handleArrowClick("right")}
            >
              <ArrowRight />
            </button>
          </div>
          <div style={{ height: "70vh" }}></div>
        </section>
        <div id="atlas-product">
          <section className="left-section">
            <div>
              <div>
                <div className="catchy">{content.section4.title1}</div>
                <h2>{content.section4.title2}</h2>
                <p>{content.section4.description}</p>
                <Link to={content.section4.link}>
                  <button className="mach9">
                    <div>
                      {translations.request[pageContext.language]}
                      <ArrowIcon />
                    </div>
                  </button>
                </Link>
              </div>
            </div>
          </section>
          <div className="right-section">
            <div>
              <img
                src={content.section4.image.publicURL}
                width={300}
                height={300}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
        <section id="atlas-conversation">
          <div className="hero-atlas">
            {/* <div className="catchy">{content.section5.title1}</div> */}
            <h2>{content.section5.title2}</h2>
            <p>{content.section5.description}</p>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="globe"></div>
              <div>
                <div className="globe-title">
                  {content.section5.globe?.title1}
                </div>
                <p className="globe-description">
                  {content.section5.globe?.description1}
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="pathways"></div>
              <div>
                <div className="globe-title">
                  {content.section5.globe?.title2}
                </div>
                <p className="globe-description">
                  {content.section5.globe?.description2}
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </section>
        <section id="atlas_sub_modules">
          <h2> {content?.section5?.heading?.title} </h2>

          <div className="sub_modules_content">
            {content?.section5?.submodules?.map((item) => {
              return (
                <div className="inner_div">
                  <p>{item?.title}</p>
                  <img src={item?.image?.publicURL} />
                </div>
              );
            })}
          </div>
        </section>
        <section id="voices_section">
          <h2>{content?.section5?.voices?.title}</h2>
          <p>{content?.section5?.voices?.description}</p>

          <h4>{content?.section5?.voices?.example}</h4>
          <video
            width="320"
            height="240"
            id="video-atlas"
            controls={true}
            loop={false}
            muted={true}
            preload={"auto"}
            autoPlay={true}
            src={assetServer + "/videos/voices.mp4"}
            type="video/mp4"
            style={{ width: "100%", height: "100%", paddingTop: "20px" }}
          ></video>
        </section>
        <section id="atlas-contact">
          <div style={{ textAlign: "center", marginBottom: "6rem" }}>
            <div className="catchy">{content?.section6?.title1}</div>
            <h2>{content?.section6?.title2}</h2>
          </div>
          <div>
            <div id="mc_embed_shell">
              <div id="mc_embed_signup">
                <form
                  ref={formRef}
                  action="https://mach9.us21.list-manage.com/subscribe/post?u=62ad7343ef0e4943943c53ebd&amp;id=de80344cbb&amp;f_id=009780e1f0"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  class="validate"
                  target="_self"
                  novalidate=""
                >
                  <div id="mc_embed_signup_scroll">
                    <div className="fields">
                      <div>
                        <div class="mc-field-group">
                          <label for="mce-MMERGE1">
                            {content.section6.contactbox.namefield.label}
                          </label>
                          <input
                            type="text"
                            name="MMERGE1"
                            class=" text"
                            id="mce-MMERGE1"
                            placeholder="John Doe"
                          />
                        </div>
                        <div class="mc-field-group">
                          <label for="mce-EMAIL">
                            {content.section6.contactbox.emailfield.label}
                          </label>
                          <input
                            type="email"
                            name="EMAIL"
                            class="required email"
                            id="mce-EMAIL"
                            required=""
                            placeholder="johndoe@gmail.com"
                          />
                        </div>
                        <div class="mc-field-group">
                          <label for="mce-MMERGE2"> {content?.section6.contactbox.inquiryfield.label} </label>
                          <select name="MMERGE2" class="" id="mce-MMERGE2">
                            <option value=""></option>
                            <option value="General questions">
                            {content?.section6.contactbox.inquiryfield.options[0].label}
                            </option>
                            <option value="Schedule a demo">
                              {content?.section6.contactbox.inquiryfield.options[1].label}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="mc-field-group">
                        <label for="mce-MMERGE3">
                          {content.section6.contactbox.commentfield.label}
                        </label>
                        <textarea
                          name="MMERGE3"
                          class=" text"
                          id="mce-MMERGE3"
                          placeholder={
                            content.section6.contactbox.commentfield.placeholder
                          }
                        />
                      </div>
                    </div>
                    <div id="mce-responses" class="clear foot">
                      <div
                        class="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        class="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>
                    <div
                      aria-hidden="true"
                      style={{ position: "absolute", left: "-5000px" }}
                    >
                      /* real people should not fill this in and expect good
                      things - do not remove this or risk form bot signups */
                      <input
                        type="text"
                        name="b_62ad7343ef0e4943943c53ebd_de80344cbb"
                        tabindex="-1"
                        value=""
                      />
                    </div>
                    <div class="optionalParent">
                      <div class="clear foot">
                        <div className="button-wrapper">
                          <div
                            className="button mach9"
                            onClick={() => {
                              if (formRef.current) formRef.current.submit();
                            }}
                          >
                            <div>
                              <input
                                type="submit"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                class="button"
                                value={translations.send[pageContext.language]}
                              />
                              <ArrowIcon />
                            </div>
                          </div>
                        </div>
                        <p style={{ margin: "0px auto" }}>
                          <a
                            href="http://eepurl.com/ilSOFI"
                            title="Mailchimp - email marketing made easy and fun"
                          >
                            <span
                              style={{
                                display: "inline-block",
                                backgroundColor: "transparent",
                                borderRadius: "4px",
                              }}
                            >
                              <img
                                class="refferal_badge"
                                src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                                alt="Intuit Mailchimp"
                                style={{
                                  width: "220px",
                                  height: "40px",
                                  display: "flex",
                                  padding: "2px 0px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              />
                            </span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

AtlasPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AtlasPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { en: { templateKey: { eq: "atlas-page" } } }) {
      frontmatter {
        en {
          herosection {
            title
            description
            backgroundimage {
              publicURL
            }
          }
          section2 {
            backgroundimageleft {
              publicURL
            }
            backgroundimageright {
              publicURL
            }
          }
          section3 {
            title1
            title2
            description
            solutions {
              title
              icon {
                publicURL
              }
              description
            }
          }
          section4 {
            title1
            title2
            description
            image {
              publicURL
            }
            link
          }
          section5 {
            title1
            title2
            description
            types {
              title
              image {
                publicURL
              }
            }
            globe {
              title1
              description1
              title2
              description2
            }
            heading {
              title
            }
            submodules {
              title
              image {
                publicURL
              }
            }
            voices {
              title
              description
              example
            }
          }
          section6 {
            title1
            title2
            contactbox {
              namefield {
                label
              }
              emailfield {
                label
              }
              inquiryfield {
                label
                options {
                  label
                  value
                }
              }
              commentfield {
                label
                placeholder
              }
            }
          }
        }
        it {
          herosection {
            title
            description
            backgroundimage {
              publicURL
            }
          }
          section2 {
            backgroundimageleft {
              publicURL
            }
            backgroundimageright {
              publicURL
            }
          }
          section3 {
            title1
            title2
            description
            solutions {
              title
              icon {
                publicURL
              }
              description
            }
          }
          section4 {
            title1
            title2
            description
            image {
              publicURL
            }
            link
          }
          section5 {
            title1
            title2
            description
            types {
              title
              image {
                publicURL
              }
            }
            globe {
              title1
              description1
              title2
              description2
            }
            heading {
              title
            }
            submodules {
              title
              image {
                publicURL
              }
            }
            voices {
              title
              description
              example
            }
          }
          section6 {
            title1
            title2
            contactbox {
              namefield {
                label
              }
              emailfield {
                label
              }
              inquiryfield {
                label
                options {
                  label
                  value
                }
              }
              commentfield {
                label
                placeholder
              }
            }
          }
        }
        es {
          herosection {
            title
            description
            backgroundimage {
              publicURL
            }
          }
          section2 {
            backgroundimageleft {
              publicURL
            }
            backgroundimageright {
              publicURL
            }
          }
          section3 {
            title1
            title2
            description
            solutions {
              title
              icon {
                publicURL
              }
              description
            }
          }
          section4 {
            title1
            title2
            description
            image {
              publicURL
            }
          }
          section5 {
            title1
            title2
            description
            types {
              title
              image {
                publicURL
              }
            }
          }
          section6 {
            title1
            title2
            contactbox {
              namefield {
                label
              }
              emailfield {
                label
              }
              inquiryfield {
                label
                options {
                  label
                  value
                }
              }
              commentfield {
                label
                placeholder
              }
            }
          }
        }
      }
    }
  }
`;
